import { FormEvent, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../components/Button'
import Logo from '../components/Logo'
import Select from '../components/Select'
import TextInput from '../components/TextInput'
import ToggleButtons from '../components/ToggleButtons'
import useApi from '../hooks/useApi'
import useAuth from '../hooks/useAuth'

type Props = {}

const Registration: React.FC<Props> = () => {
  const [data, setData] = useState<{
    email: string
    password: string
    role: string
    firstname: string
    lastname: string
    location: string
    city_request: string
  }>({
    email: '',
    password: '',
    role: '',
    firstname: '',
    lastname: '',
    location: '',
    city_request: '',
  })
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const { setToken } = useAuth()
  const navigate = useNavigate()
  const api = useApi()

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      const { token } = await api('/register', {
        username: data.email,
        password: data.password,
        meta: {
          firstname: data.firstname,
          lastname: data.lastname,
          role: data.role,
        },
      })

      if (token) {
        localStorage.setItem('token', token)
        setToken(token)
        navigate('/')
      }
    },
    [api, data, navigate, setToken]
  )

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: 'var(--space-xxl)',
      }}>
      <Logo />
      <h2 style={{ marginBottom: 'var(--space-lg)' }}>Registration</h2>
      <form
        onSubmit={onSubmit}
        style={{
          width: '100%',
          flex: 1,
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}>
        {currentIndex === 0 && (
          <>
            <h2 style={{ margin: 'var(--space-md) 0' }}>Mein Standort</h2>
            <Select
              style={{ marginBottom: 'var(--space-lg)' }}
              label='Ich wohne in'
              options={[
                { value: 'Aachen', label: 'Aachen' },
                { value: 'kreisaachen', label: 'Kreis Aachen' },
                { value: 'pending', label: 'Meine Stadt vorschlagen' },
              ]}
              onChange={val => setData(prev => ({ ...prev, location: val }))}
            />

            {data.location === 'pending' && (
              <TextInput
                style={{ marginBottom: 'var(--space-md)' }}
                label='Meine Stadt vorschlagen'
                placeholder='Meine Stadt'
                onChange={val =>
                  setData(prev => ({ ...prev, city_request: val }))
                }
              />
            )}
          </>
        )}
        {currentIndex === 2 && (
          <>
            <h2 style={{ margin: 'var(--space-md) 0' }}>Persönliche Daten</h2>
            <TextInput
              style={{ marginBottom: 'var(--space-md)' }}
              label='Nachname'
              placeholder='Nachname'
              onChange={val => setData(prev => ({ ...prev, lastname: val }))}
            />
            <TextInput
              style={{ marginBottom: 'var(--space-md)' }}
              label='Vorname'
              placeholder='Vorname'
              onChange={val => setData(prev => ({ ...prev, firstname: val }))}
            />

            {/* <Select
              label='Ich bin'
              options={[
                { value: 'Schueler', label: 'Schueler' },
                { value: 'Gefluechtet', label: 'Geflüchtet' },
              ]}
              onChange={val => setData(prev => ({ ...prev, role: val }))}
            /> */}
            <h2 style={{ margin: 'var(--space-md) 0' }}>Ich bin</h2>
            <ToggleButtons
              onChange={opt => setData(prev => ({ ...prev, role: opt.value }))}
              options={[
                { value: 'Schueler', label: 'Schüler' },
                { value: 'Gefluechtet', label: 'Geflüchtet' },
                { value: 'Spender', label: 'Spender / Unternehmen' },
              ]}
            />
          </>
        )}

        {currentIndex === 1 && (
          <>
            <h2>Account Daten</h2>

            <TextInput
              isDisabled={data.location === 'pending'}
              label='E-Mail'
              style={{ marginBottom: 'var(--space-md)' }}
              placeholder='E-Mail'
              type='email'
              onChange={val => setData(prev => ({ ...prev, email: val }))}
            />

            <TextInput
              isDisabled={data.location === 'pending'}
              label='Passwort'
              style={{ marginBottom: 'var(--space-xl)' }}
              placeholder='Passwort'
              type='password'
              onChange={val => setData(prev => ({ ...prev, password: val }))}
            />
          </>
        )}

        <Button
          onClick={() => {
            if (data.city_request.length >= 2) {
              return
            }
            if (currentIndex < 2) {
              setCurrentIndex(prev => prev + 1)
            } else {
              //
            }
          }}
          isDisabled={
            (data.location === 'pending' && data.city_request.length < 2) ||
            (currentIndex === 2 && !data.role)
          }
          type='submit'
          style={{
            display: 'block',
            width: '100%',
            padding: 'var(--space-lg) var(--space-xl)',
          }}>
          <h2>
            {data.location === 'pending'
              ? 'Jetzt Stadt vorschlagen'
              : currentIndex === 2
              ? 'Jetzt registrieren'
              : 'Weiter'}
          </h2>
        </Button>
      </form>
    </div>
  )
}

export default Registration
