import { ReactComponent as DashboardIcon } from '../assets/icons/ic_dashboard.svg'
import { ReactComponent as RedeemIcon } from '../assets/icons/ic_redeem.svg'
import { ReactComponent as AppointmentsIcon } from '../assets/icons/ic_appointments.svg'
import { ReactComponent as ProfileIcon } from '../assets/icons/ic_profile.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMemo } from 'react'
import useAuth from '../hooks/useAuth'
type Props = {}

const items: IFooterItem[] = [
  {
    label: 'Erkunden',
    Icon: DashboardIcon,
    pathName: 'discover',
  },
  {
    label: 'Belohnungen',
    Icon: RedeemIcon,
    pathName: 'redeem',
  },
  {
    label: 'Termine',
    Icon: AppointmentsIcon,
    pathName: 'appointments',
  },
  {
    label: 'Profil',
    Icon: ProfileIcon,
    pathName: 'profile',
  },
]

const Footer: React.FC<Props> = () => {
  const location = useLocation()

  const { token } = useAuth()

  const path = useMemo(
    () => (location.pathname === '/' ? '/discover' : location.pathname),
    [location]
  )

  return (
    (!!token && (
      <div className='footer'>
        {items.map(item => (
          <FooterItem
            key={item.pathName}
            {...item}
            pathName={item.pathName}
            active={path === `/${item.pathName}`}
          />
        ))}
      </div>
    )) || <></>
  )
}

export default Footer

type IFooterItem = {
  pathName: string
  label: string
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  active?: boolean
}
const FooterItem: React.FC<IFooterItem> = ({
  label,
  Icon,
  active,
  pathName,
}) => {
  const navigate = useNavigate()

  return (
    <div className='footer__item' onClick={() => navigate(`/${pathName}`)}>
      <div className='footer__item__icon__wrapper'>
        <Icon color={active ? 'var(--color-primary)' : 'var(--color-text)'} />
      </div>
      {/* <div>{label}</div> */}
    </div>
  )
}
