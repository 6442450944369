import { ClassComponent } from '../types'

interface Props extends ClassComponent {
  fill?: string
  width?: number | string
  height?: number | string
}

const Rectangle: React.FC<Props> = ({ fill = 'primary', width, height }) => {
  return (
    <svg
      style={{ objectFit: 'cover' }}
      width={width || '93'}
      height={height || '41'}
      viewBox='0 0 93 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M92.8381 0.987823L6.24314 5.73352L0.442531 40.3492L87.0374 36.4409L92.8381 0.987823Z'
        fill={`var(--color-${fill})`}
      />
    </svg>
  )
}

export default Rectangle
