import { userInfo } from 'os'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { ITaskItem } from '../components/TaskItem'
import { GGProvider } from '../types'

export type GGUser = {
  credits?: number
  name?: string
  location?: string
  appointments?: ITaskItem[]
}

type GGAuthContext = {
  token: string
  setToken: (tok: string) => any
  user: GGUser
  setUser: (user: GGUser) => any
}

const AuthContext = createContext<GGAuthContext>({
  token: '',
  setToken: () => null,
  user: {},
  setUser: () => null,
})

export const AuthProvider: React.FC<GGProvider> = ({ children }) => {
  const auth = useAuthProvider()

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}

const useAuthProvider = () => {
  const [token, setToken] = useState<string>('')
  const [user, _setUser] = useState<GGUser>({})

  const getUser = async () => {
    const tok = localStorage.getItem('token')
    setToken(tok || '')

    if (tok) {
      const json = localStorage.getItem('user') || ''
      const user = (await JSON.parse(json)) as GGUser

      _setUser(user)
    }
  }

  useEffect(() => {
    getUser()
  }, [])

  const setUser = useCallback(
    (u: Partial<GGUser>) => {
      _setUser(prev => ({ ...prev, ...u }))
      localStorage.setItem('user', JSON.stringify({ ...user, ...u }))
    },
    [user]
  )

  return { token, setToken, user, setUser }
}

export default useAuth
