import { loadOptions } from '@babel/core'
import { ReactNode, useState } from 'react'
import { ClassComponent } from '../types'

interface Props extends ClassComponent {
  options: { label: string; value: string }[]
  onChange: (val: string) => any
  label?: string
  style?: React.CSSProperties
  isDisabled?: boolean
}

const Select: React.FC<Props> = ({
  options,
  onChange,
  label,
  style,
  isDisabled,
}) => {
  const [value, setValue] = useState<string>(options[0].value)

  return (
    <div className={`select ${isDisabled ? 'disabled' : ''}`} style={style}>
      {label && <label>{label}</label>}
      <div className='select__inner'>
        <select
          disabled={isDisabled}
          onChange={e => {
            setValue(e.target.value)
            onChange && onChange(e.target.value)
          }}>
          {options.map(opt => (
            <option value={opt.value}>{opt.label}</option>
          ))}
        </select>
        <div className='select__value'>
          {
            Object.entries(options).find(
              ([key, val]) => val.value === value
            )?.[1].label
          }
        </div>
      </div>
    </div>
  )
}

export default Select
