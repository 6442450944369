import { ReactNode } from 'react'
import { ClassComponent } from '../types'

interface Props extends ClassComponent {
  children: ReactNode | ReactNode[]
  variant?: 'normal' | 'dark' | 'accent' | 'link'
  onClick?: () => any
  type?: 'button' | 'submit'
  style?: React.CSSProperties
  isDisabled?: boolean
}

const Button: React.FC<Props> = ({
  style,
  className,
  children,
  type = 'button',
  variant = 'normal',
  onClick,
  isDisabled,
}) => {
  return (
    <button
      disabled={isDisabled}
      type={type}
      style={style}
      onClick={onClick}
      className={`btn btn--${variant} ${className}`}>
      {children}
    </button>
  )
}

export default Button
