import { setDefaultResultOrder } from 'dns'
import { FormEvent, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../components/Button'
import Logo from '../components/Logo'

import TextInput from '../components/TextInput'
import useApi from '../hooks/useApi'

import useAuth, { GGUser } from '../hooks/useAuth'

type Props = {}

const Login: React.FC<Props> = () => {
  const [data, setData] = useState<{
    email: string
    password: string
  }>({ email: '', password: '' })
  const { setToken, setUser } = useAuth()
  const navigate = useNavigate()
  const api = useApi()

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      // const { token } = await api('/login', {
      //   username: data.email,
      //   password: data.password,
      // })
      const token = 'meintoken'
      if (token) {
        localStorage.setItem('token', token)
        setToken(token)
        const json = localStorage.getItem('user') || ''

        let user: GGUser = {
          name: 'Nele',
          credits: 500,
          appointments: [
            {
              img: 'https://media.istockphoto.com/id/1297274419/de/foto/schulgeb%C3%A4ude-mit-schulhof.webp?s=612x612&w=is&k=20&c=E1D5ZaqMmVHOGNNJHBPKSaysecavCEHlFE5mfbE0WZQ=',
              issuer: 'Gymnasium XY',
              date: 'heute 18:00',
              duration: 'ca. 2h',
              reward: 100,
              title: 'Schulhof säubern',
            },
          ],
        }

        try {
          user = (await JSON.parse(json)) as GGUser
        } catch (e) {
        } finally {
          setUser(user)
          navigate('/')
        }
      }
    },
    [navigate, setToken, setUser]
  )

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}>
      <Logo />
      <h2 style={{ marginBottom: 'var(--space-lg)' }}>Login</h2>
      <form onSubmit={onSubmit} style={{ width: '100%' }}>
        <TextInput
          label='E-Mail'
          style={{ marginBottom: 'var(--space-md)' }}
          placeholder='E-Mail'
          type='email'
          onChange={val => setData(prev => ({ ...prev, email: val }))}
        />

        <TextInput
          label='Passwort'
          style={{ marginBottom: 'var(--space-xl)' }}
          placeholder='Passwort'
          type='password'
          onChange={val => setData(prev => ({ ...prev, password: val }))}
        />

        <Button
          type='submit'
          style={{
            display: 'block',
            width: '100%',
            padding: 'var(--space-lg) var(--space-xl)',
          }}>
          <h2>Einloggen</h2>
        </Button>
      </form>
      <div style={{ marginTop: 'var(--space-lg)' }}>
        <Button variant='link' onClick={() => navigate('/registration')}>
          Jetzt registrieren
        </Button>
      </div>
    </div>
  )
}

export default Login
