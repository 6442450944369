import { createContext, useContext, useState } from 'react'
import { GGProvider } from '../types'

type GGApp = {
  showHeader?: boolean
  setShowHeader: (showHeader: boolean) => any
}
const AppContext = createContext<GGApp>({ setShowHeader: () => null })

export const AppProvider: React.FC<GGProvider> = ({ children }) => {
  const app = useAppProvider()
  return <AppContext.Provider value={app}>{children}</AppContext.Provider>
}

const useApp = () => {
  return useContext(AppContext)
}

const useAppProvider = () => {
  const [state, setState] = useState<Partial<GGApp>>({ showHeader: true })
  return {
    showHeader: state.showHeader,
    setShowHeader: showHeader => setState(prev => ({ ...prev, showHeader })),
  } as GGApp
}

export default useApp
