import { useNavigate } from 'react-router-dom'
import useApp from '../hooks/useApp'
import useAuth from '../hooks/useAuth'
import Button from './Button'

type Props = {}

const Header: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { showHeader } = useApp()
  const { token, user } = useAuth()

  return (
    (token && showHeader && (
      <div className='header'>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
          }}>
          <img
            onClick={() => navigate('/profile')}
            width={38}
            height={38}
            style={{
              cursor: 'pointer',
              objectFit: 'cover',
              borderRadius: 100,
              border: 'solid 2px var(--color-primaryDark)',
            }}
            src='https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
          />
          <h3 style={{ marginLeft: 'var(--space-md)' }}>Hallo {user.name}!</h3>
        </div>
        <Button
          variant='dark'
          onClick={() => navigate('/redeem')}
          className='header__btn--points'>
          {user.credits} P
        </Button>
      </div>
    )) || <></>
  )
}
export default Header
