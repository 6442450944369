import { ClassComponent } from '../types'

interface Props extends ClassComponent {}

export interface IRewardItem {
  issuer: string
  description: string
  cost: number
  img?: string
  highlight?: boolean
  location?: string
  onClick?: () => any
}

const RewardItemCard: React.FC<Props & IRewardItem> = ({
  className,
  issuer,
  description,
  cost,
  img,
  highlight,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={`rewarditem ${highlight ? 'unlocked' : ''} rewarditem--card`}>
      <div className='rewarditem__img__wrapper'>
        <img className='rewarditem__img' src={img} />
      </div>
      <div className='rewarditem__content'>
        <div className='rewarditem__issuer'>{issuer}</div>
        <div className='rewarditem__description'>{description}</div>

        <div className='rewarditem__cost__wrapper'>
          <div className='rewarditem__cost highlight'>{cost} P</div>
        </div>
      </div>
    </div>
  )
}

export default RewardItemCard
