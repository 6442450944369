import { useState } from 'react'
import { ClassComponent } from '../types'

interface GGToggleButton extends ClassComponent {
  label: string
  value: string
  onClick?: () => any
  active?: boolean
}

const ToggleButton: React.FC<GGToggleButton> = ({
  label,
  value,
  onClick,
  active,
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        width: '100%',
        padding: 'var(--space-lg) var(--space-xl)',
        borderRadius: 8,
        backgroundColor: active
          ? 'var(--color-primaryDark)'
          : 'var(--color-primaryLight)',
      }}>
      <strong
        style={{
          fontSize: 16,
          color: active ? 'var(--color-text-light)' : 'var(--color-text)',
        }}>
        {label}
      </strong>
    </div>
  )
}

interface GGToggleButtons extends ClassComponent {
  options: GGToggleButton[]
  onChange?: (opt: GGToggleButton) => any
}
const ToggleButtons: React.FC<GGToggleButtons> = ({ options, onChange }) => {
  const [selected, setSelected] = useState<GGToggleButton>()

  const select = (opt: GGToggleButton) => {
    setSelected(opt)
    onChange && onChange(opt)
  }

  return (
    <div style={{ width: '100%' }}>
      {options.map(opt => (
        <div style={{ margin: 'var(--space-md) 0' }}>
          <ToggleButton
            {...opt}
            active={selected?.value === opt.value}
            onClick={() => select(opt)}
          />
        </div>
      ))}
    </div>
  )
}

export default ToggleButtons
