import { ClassComponent } from '../types'

interface Props extends ClassComponent {
  placeholder?: string
  variant?: 'normal'
  onChange: (val: string) => any
  type?: 'text' | 'email' | 'password'
  style?: React.CSSProperties
  label?: string
  isDisabled?: boolean
}

const TextInput: React.FC<Props> = ({
  className,
  type = 'text',
  onChange,
  placeholder,
  style,
  label,
  isDisabled,
}) => {
  return (
    <div
      className={`textinput ${className || ''} ${isDisabled ? 'disabled' : ''}`}
      style={style}>
      {label && <label>{label}</label>}
      <input
        disabled={isDisabled}
        placeholder={placeholder}
        type={type}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  )
}

export default TextInput
