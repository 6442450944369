import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useApp from '../hooks/useApp'
import useAuth from '../hooks/useAuth'

type Props = {}

const Profile: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { setShowHeader } = useApp()
  const { token, setToken, user } = useAuth()

  useEffect(() => {
    setShowHeader(false)
    return () => setShowHeader(!!token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <div>
      <div
        style={{
          minHeight: 150,
          padding: 'var(--space-lg) 0 calc(75px + var(--space-lg)) 0',
          position: 'relative',
          background: 'var(--color-primary)',
          margin: '0 calc(var(--space-lg) * -1)',
          boxShadow: 'var(--box-shadow)',
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <img
          // onClick={() => navigate('/profile')}
          alt='profile'
          width={150}
          height={150}
          style={{
            cursor: 'pointer',
            objectFit: 'cover',
            borderRadius: 100,
            border: 'solid 2px var(--color-accent)',
          }}
          src='https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
        />
        <h1
          style={{
            color: 'var(--color-secondary)',
            fontSize: 'var(--fontsize-xxxl)',
          }}>
          {user.name}
        </h1>
        <h1
          className='highlight highlight--accent'
          style={{ color: 'var(--color-text-light)' }}>
          {user.credits} P
        </h1>
        <img
          alt='qr'
          width={150}
          height={150}
          style={{
            position: 'absolute',
            bottom: '-75px',
            objectFit: 'cover',
            background: 'white',
            objectPosition: '0 0px',
            borderRadius: 10,
            boxShadow: 'var(--box-shadow)',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
          src='https://cdn3.vectorstock.com/i/1000x1000/50/37/qr-code-with-text-lorem-ipsum-vector-28155037.jpg'
        />
      </div>
      <div
        style={{
          paddingTop: '75px',
        }}>
        <ul className='list'>
          <li>
            <a href='#'>
              <div>
                <h3>Einstellungen</h3>
              </div>
            </a>
          </li>
          <li>
            <a href='#'>
              <div>
                <h3>Häufig gestellte Fragen</h3>
              </div>
            </a>
          </li>
          <li>
            <a href='#'>
              <div>
                <h3>Kontakt</h3>
              </div>
            </a>
          </li>
          <li
            onClick={() => {
              localStorage.removeItem('token')
              setShowHeader(false)
              setToken('')
              navigate('/login')
            }}>
            <a href='#'>
              <div>
                <h3>Abmelden</h3>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Profile
