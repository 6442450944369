import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Header from './components/Header'
import Footer from './components/Footer'
import Redeem from './pages/Redeem'
import Appointments from './pages/Appointments'
import Profile from './pages/Profile'

import { AppProvider } from './hooks/useApp'
import Modal from './components/Modal'
import { ModalProvider } from './hooks/useModal'
import Registration from './pages/Registration'
import TaskDetail from './pages/TaskDetail'
import { AuthProvider } from './hooks/useAuth'
import RewardDetail from './pages/RewardDetail'

function App() {
  return (
    <AppProvider>
      <ModalProvider>
        <AuthProvider>
          <div className='app'>
            <BrowserRouter>
              <Header />
              <div className='app__content'>
                <Routes>
                  <Route
                    path='/'
                    element={
                      <RequireAuth>
                        <Dashboard />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/discover'
                    element={<Navigate to={'/'} replace />}
                  />
                  <Route
                    path='/redeem'
                    element={
                      <RequireAuth>
                        <Redeem />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/task'
                    element={
                      <RequireAuth>
                        <TaskDetail />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/reward'
                    element={
                      <RequireAuth>
                        <RewardDetail />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/appointments'
                    element={
                      <RequireAuth>
                        <Appointments />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/profile'
                    element={
                      <RequireAuth>
                        <Profile />
                      </RequireAuth>
                    }
                  />
                  <Route path='/login' element={<Login />} />
                  <Route path='/registration' element={<Registration />} />
                  <Route
                    path='*'
                    element={
                      <RequireAuth>
                        <Dashboard />
                      </RequireAuth>
                    }
                  />
                </Routes>
              </div>
              <Footer />
            </BrowserRouter>
          </div>
          <Modal />
        </AuthProvider>
      </ModalProvider>
    </AppProvider>
  )
}

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation()
  const token = localStorage.getItem('token')

  if (!token) return <Navigate to='/login' state={{ from: location }} replace />
  return children
}

export default App
