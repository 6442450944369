import { ClassComponent } from '../types'
import Rectangle from './Rectangle'

interface Props extends ClassComponent {}

const Logo: React.FC<Props> = () => {
  return (
    <div style={{ position: 'relative', zIndex: 1 }}>
      <h1 style={{ marginBottom: 'var(--space-lg)', fontSize: 56, zIndex: 1 }}>
        fillip*
      </h1>
      <div style={{ position: 'absolute', bottom: 0, left: 0, zIndex: -1 }}>
        <Rectangle width={140} height={80} />
      </div>
    </div>
  )
}

export default Logo
