import React from 'react'
import { useNavigate } from 'react-router-dom'
import TaskItem, { ITaskItem } from '../components/TaskItem'
import TeaserHeadline from '../components/TeaserHeadline'
import useAuth from '../hooks/useAuth'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
type Props = {}

const Appointments: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { user } = useAuth()

  return (
    <div>
      <Calendar />

      <TeaserHeadline
        title='Deine Aufgaben'
        titleStyle={{ display: 'inline-block', margin: 'var(--space-lg) 0' }}
      />

      <div className='listview'>
        {(user?.appointments &&
          user?.appointments?.length > 0 &&
          user?.appointments?.map((t: ITaskItem) => (
            <TaskItem
              {...t}
              highlight={!!t.date.match(/heute/i)}
              onClick={() => navigate('/task', { state: { task: t } })}
            />
          ))) || <p>Du hast momentan keine Aufgaben offen.</p>}
      </div>
    </div>
  )
}

export default Appointments
