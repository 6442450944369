import { useCallback } from 'react'
import useAuth from './useAuth'

const useApi = () => {
  const { token } = useAuth()
  const api = useCallback(
    async (path: string, data: any) => {
      const headers: HeadersInit = {}

      token && (headers.Authorization = `Bearer ${token}`)

      const res = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      })

      const json = await res.json()

      return json
    },
    [token]
  )
  return api
}

export default useApi
