import Button from './Button'

type Props = {
  title: string
  showMore?: boolean
  titleStyle?: React.CSSProperties
}

const TeaserHeadline: React.FC<Props> = ({ title, titleStyle, showMore }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <div style={{ flex: 1 }}>
        <h2 className='highlight' style={{ ...titleStyle }}>
          {title}
        </h2>
      </div>
      {showMore && <Button variant='link'>Mehr</Button>}
    </div>
  )
}

export default TeaserHeadline
