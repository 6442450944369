import { createContext, ReactNode, useContext, useState } from 'react'

import { GGProvider } from '../types'

export type GGModal = {
  showModal: boolean
  header?: ReactNode | ReactNode[]
  content?: ReactNode | ReactNode[]
  footer?: ReactNode | ReactNode[]
  title?: string
  variant?: 'normal' | 'camera'
}

const ModalContext = createContext<{
  modal: GGModal
  setModal: React.Dispatch<React.SetStateAction<GGModal>>
}>({
  modal: { showModal: false, header: null, content: null, footer: null },
  setModal: () => null,
})

export const ModalProvider: React.FC<GGProvider> = ({ children }) => {
  const modal = useModalProvider()
  return <ModalContext.Provider value={modal}>{children}</ModalContext.Provider>
}

const useModal = () => {
  return useContext(ModalContext)
}

const useModalProvider = () => {
  const [modal, setModal] = useState<GGModal>({
    showModal: false,
    header: null,
    content: null,
    footer: null,
  })
  return { modal, setModal }
}

export default useModal
