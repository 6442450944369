import { ClassComponent } from '../types'
import { IRewardItem } from './RewardItemCard'

interface Props extends ClassComponent {}

const RewardItemRow: React.FC<IRewardItem> = ({
  issuer,
  description,
  cost,
  img,
  location,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={`rewarditem ${cost < 1200 ? 'unlocked' : ''} rewarditem--row`}>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'center',
        }}>
        <div className='rewarditem__img__wrapper'>
          <img className='rewarditem__img' src={img} />
        </div>
        <div style={{ marginLeft: 'var(--space-md)' }}>
          <div className='rewarditem__issuer'>{issuer}</div>
          <div>{location}</div>
        </div>
      </div>
      <div className='rewarditem__content'>
        <div className='rewarditem__description'>{description}</div>

        <div className='rewarditem__cost__wrapper'>
          <div className='rewarditem__cost highlight'>{cost} P</div>
        </div>
      </div>
    </div>
  )
}

export default RewardItemRow
