import React, { useEffect, useState } from 'react'
import useModal from '../hooks/useModal'
import BarcodeScannerComponent from '@steima/react-qr-barcode-scanner'
import Button from './Button'
import useAuth from '../hooks/useAuth'

const Modal: React.FC = () => {
  const [isRenderCamera, setIsRenderCamera] = useState(false)
  const { user, setUser } = useAuth()
  const {
    modal: { showModal, header, content, footer, title, variant = 'normal' },
    setModal,
  } = useModal()

  const buildHeader = <>{title}</>
  const buildFooter = <></>

  const checkScan = (err: any, val: any) => {
    if (err) return
    if (val.text === 'success') {
      _showModal()
    }
  }

  const _showModal = () => {
    setModal(prev => ({ ...prev, showModal: false }))
    setTimeout(() => {
      setIsRenderCamera(false)
      setModal(prev => ({
        ...prev,
        variant: 'normal',
        showModal: true,
        content: (
          <>
            <h1>Hurra!</h1>
            <p>
              Der QR-Code wurde erfolgreich gescannt! Du kannst dir jetzt deine
              Belohnung abholen!
            </p>
            <div>
              <Button
                onClick={() => {
                  setUser({ credits: (user.credits || 0) + 100 })
                  setModal(prev => ({ ...prev, showModal: false }))
                }}>
                Weiter
              </Button>
            </div>
          </>
        ),
      }))
    }, 1000)
  }

  useEffect(() => {
    setIsRenderCamera(variant === 'camera')
  }, [variant])

  const renderHeader = header || buildHeader
  const renderFooter = footer || buildFooter
  const renderCamera = isRenderCamera && (
    <>
      {navigator.mediaDevices && (
        <div style={{ flex: 1 }}>
          <BarcodeScannerComponent
            onError={err => console.log(err)}
            height={'100%'}
            width={'100%'}
            onUpdate={checkScan}
          />
        </div>
      )}

      <div
        style={{
          display: 'flex',
          padding: 'var(--space-md)',
          flexFlow: 'row nowrap',
          justifyContent: 'space-between',
          alignItems: 'space-between',
          width: 100 + '%',
        }}>
        <Button onClick={() => checkScan(null, { text: 'success' })}>
          Fake QR-Scan
        </Button>
        <Button
          onClick={() => setModal(prev => ({ ...prev, showModal: false }))}>
          Schließen
        </Button>
      </div>
    </>
  )

  return (
    <div className={`modal${showModal ? ' show' : ''} modal--${variant}`}>
      <div className='modal__body'>
        <div className='modal__header'>{renderHeader}</div>
        <div className='modal__content'>
          {(variant === 'camera' && renderCamera) || (showModal && content)}
        </div>
        <div className='modal__footer'>{renderFooter}</div>
      </div>
    </div>
  )
}

export default Modal
