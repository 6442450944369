import React from 'react'
import { useNavigate } from 'react-router-dom'
import RewardItemCard, { IRewardItem } from '../components/RewardItemCard'
import TaskItem, { ITaskItem } from '../components/TaskItem'
import TeaserHeadline from '../components/TeaserHeadline'
import useAuth from '../hooks/useAuth'

type Props = {}

const discover: ITaskItem[] = [
  {
    img: 'https://www.baesweiler.de/files/Aktuelles/Aktuelles/Logos%20intern/Logo%20mit%20L%C3%B6we%20%28Weichzeichner%29%20farbig.JPG',
    issuer: 'Stadt Baesweiler',
    date: 'in 2 Wochen',
    duration: '2h - 8h',
    reward: 1500,
    title: 'Renovierungshilfe',
  },
  {
    issuer: 'Haus Setterich',
    date: '27.09.2022',
    duration: '4h',
    reward: 500,
    title: 'Grillen & Getränke austeilen',
    img: 'https://www.drk.ac/fileadmin/_processed_/1/1/csm_haus-setterich-teaser_2f3a3cd147.jpg',
  },
  {
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Alemannia_Aachen_2010.svg/1200px-Alemannia_Aachen_2010.svg.png',
    issuer: 'Alemannia Aachen',
    date: 'in 3 Tagen',
    duration: 'ca. 2h',
    reward: 200,
    title: 'Spinte reinigen',
  },
  {
    img: 'https://reklamation.com/assets/logo/aachenmuenchener-versicherung.jpg',
    issuer: 'Aachen-Münchener',
    date: 'online',
    duration: '0.5h',
    reward: 100,
    title: 'Zufriedenheitsbefragung',
  },
]

const rewards: IRewardItem[] = [
  {
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrtdWolaIL_HnBhf3x42AY2cLUvInY51STIQ&usqp=CAU',
    issuer: 'Deutsche Bahn',
    location: 'Aachen',
    description: '1 Monats-Ticket regional',
    cost: 2000,
  },
  {
    img: 'https://edeka-geldermann.de/wp-content/uploads/2019/08/edeka-geldermann-aldehoven-aussen.jpg',
    issuer: 'Edeka Aachen',
    location: 'Aachen',
    description: '30% Rabatt auf deinen nächsten Einkauf bis 50€',
    cost: 800,
  },
  {
    img: 'https://is5-ssl.mzstatic.com/image/thumb/Purple126/v4/88/9d/a7/889da76d-0766-8e32-62ba-1e817e0912c4/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/1200x630wa.png',
    issuer: 'Burger King Aachen',
    location: 'Aachen',
    description: '1 kostenloses King-Menü nach Wahl',
    cost: 500,
  },
]

const Dashboard: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { user } = useAuth()

  return (
    <div>
      <TeaserHeadline
        title='Deine Aufgaben'
        showMore
        titleStyle={{ display: 'inline-block', margin: 'var(--space-lg) 0' }}
      />

      <div className='listview'>
        {(user?.appointments &&
          user?.appointments?.length > 0 &&
          user?.appointments?.map((t: ITaskItem) => (
            <TaskItem
              {...t}
              highlight={!!t.date.match(/heute/i)}
              onClick={() => navigate('/task', { state: { task: t } })}
            />
          ))) || <p>Du hast momentan keine Aufgaben offen.</p>}
      </div>
      <TeaserHeadline
        title='Belohnungen in deiner Nähe'
        showMore
        titleStyle={{ display: 'inline-block', margin: 'var(--space-lg) 0' }}
      />

      <div
        style={{
          marginLeft: 'calc(var(--space-lg) * -1)',
          marginRight: 'calc(var(--space-lg) * -1)',
          paddingLeft: 'var(--space-lg)',
          paddingRight: 'var(--space-lg)',
          overflowX: 'auto',
          display: 'flex',
          flexFlow: 'row nowrap',
          overflowY: 'visible',
        }}>
        {rewards.map((r: IRewardItem) => (
          <RewardItemCard
            {...r}
            highlight={(user.credits || 0) >= r.cost}
            onClick={() => navigate('/reward', { state: { reward: r } })}
          />
        ))}
      </div>
      <TeaserHeadline
        title='Das könnte dich interessieren'
        showMore
        titleStyle={{ display: 'inline-block', margin: 'var(--space-lg) 0' }}
      />

      {discover.map(
        (t: ITaskItem) =>
          user.appointments &&
          !user.appointments.find(a => a.title === t.title) && (
            <TaskItem
              {...t}
              onClick={() => navigate('/task', { state: { task: t } })}
            />
          )
      )}
    </div>
  )
}

export default Dashboard
