import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../components/Button'
import { IRewardItem } from '../components/RewardItemCard'
import useAuth from '../hooks/useAuth'

type Props = {}
interface LocationState {
  state: {
    reward: IRewardItem
  }
}
const RewardDetail: React.FC<Props> = () => {
  const navigate = useNavigate()
  const {
    state: { reward },
  } = useLocation() as LocationState

  return (
    <div className='taskdetail'>
      <Button variant='accent' onClick={() => navigate(-1)}>
        Zurück
      </Button>
      <div
        className='taskdetail__img__wrapper'
        style={{ marginTop: 'var(--space-md)' }}>
        <img className='taskdetail__img' src={reward.img} />
      </div>

      <div className='taskdetail__content'>
        <div className='taskdetail__data'>
          <div style={{ flex: 1, marginTop: 'var(--space-md)' }}>
            <h1
              className='highlight'
              style={{
                display: 'inline',
              }}>
              {reward.issuer}
            </h1>
            <div
              style={{
                marginTop: 'var(--space-md)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexFlow: 'row nowrap',
              }}>
              <h3
                className='taskdetail__title'
                style={{ marginTop: 'var(--space-sm)' }}>
                {reward.location}
              </h3>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}>
                <h2
                  className='taskdetail__reward highlight highlight--accent'
                  style={{
                    textAlign: 'right',
                    display: 'inline-block',
                  }}>
                  {reward.cost} P
                </h2>
              </div>
            </div>
          </div>

          <div style={{ width: '100%', marginTop: 'var(--space-md)' }}></div>
        </div>
        <div
          style={{
            width: '100%',
            height: '1px',
            background: 'rgba(0, 0, 0, .1)',
            margin: 'var(--space-lg) 0',
          }}></div>
        <img
          alt='qr'
          width={150}
          height={150}
          style={{
            objectFit: 'cover',
            background: 'white',
            objectPosition: '0 0px',
            borderRadius: 10,
            boxShadow: 'var(--box-shadow)',
          }}
          src='https://cdn3.vectorstock.com/i/1000x1000/50/37/qr-code-with-text-lorem-ipsum-vector-28155037.jpg'
        />
        <p
          style={{
            opacity: 0.6,
            marginBottom: 'var(--space-lg)',
            marginTop: 'var(--space-sm)',
          }}>
          Lasse diesen QR-Code bei der Annahmestelle scannen und erhalte deine
          Belohnung!
        </p>
        <h3>Beschreibung</h3>
        <p>{reward.description}</p>
        <div
          style={{
            width: '100%',
            height: '1px',
            background: 'rgba(0, 0, 0, .1)',
            margin: 'var(--space-lg) 0',
          }}></div>
        <h3>Weitere Informationen</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
        <div style={{ marginTop: 'var(--space-lg)' }}></div>
      </div>
    </div>
  )
}

export default RewardDetail
