import React, { useMemo } from 'react'
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom'
import Button from '../components/Button'
import { ITaskItem } from '../components/TaskItem'
import useAuth from '../hooks/useAuth'

type Props = {}

interface LocationState {
  state: {
    task: ITaskItem
  }
}

const TaskDetail: React.FC<Props> = () => {
  const navigate = useNavigate()
  const {
    state: { task },
  } = useLocation() as LocationState

  const { user, setUser } = useAuth()
  const isIn: boolean = useMemo(
    () => !!user.appointments?.find(a => a.title === task.title),
    [task.title, user.appointments]
  )

  return (
    <div className='taskdetail'>
      <Button variant='accent' onClick={() => navigate(-1)}>
        Zurück
      </Button>
      <div
        className='taskdetail__img__wrapper'
        style={{ marginTop: 'var(--space-md)' }}>
        <img className='taskdetail__img' src={task.img} />
      </div>

      <div className='taskdetail__content'>
        <div className='taskdetail__data'>
          <div style={{ flex: 1, marginTop: 'var(--space-md)' }}>
            <h1
              className='highlight'
              style={{
                display: 'inline',
              }}>
              {task.title}
            </h1>
            <h3
              className='taskdetail__title'
              style={{ marginTop: 'var(--space-sm)' }}>
              {task.issuer}
            </h3>
          </div>

          <div style={{ width: '100%', marginTop: 'var(--space-md)' }}>
            <div
              className='taskdetail__time'
              style={{
                display: 'flex',
                flexFlow: 'row nowrap',
                width: '100%',
              }}>
              <h4 style={{ flex: 1, fontWeight: 'normal' }}>{task.date}</h4>
              <h4 style={{ fontWeight: 'normal' }}>{task.duration}</h4>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <h2
                className='taskdetail__reward highlight highlight--accent'
                style={{
                  marginTop: 'var(--space-md)',
                  textAlign: 'right',
                  display: 'inline-block',
                }}>
                {task.reward} P
              </h2>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            height: '1px',
            background: 'rgba(0, 0, 0, .1)',
            margin: 'var(--space-lg) 0',
          }}></div>
        <h3>Beschreibung</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
        <div style={{ marginTop: 'var(--space-lg)' }}>
          <Button
            isDisabled={isIn}
            onClick={() => {
              setUser({ appointments: [...(user.appointments || []), task] })
            }}
            style={{
              width: '100%',
              padding: 'var(--space-lg) var(--space-xl)',
            }}>
            <h2>{isIn ? 'Bereits angemeldet' : 'Jetzt bewerben'}</h2>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TaskDetail
