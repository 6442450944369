import useAuth from '../hooks/useAuth'
import { ClassComponent } from '../types'

interface Props extends ClassComponent {}

export interface ITaskItem {
  issuer: string
  title: string
  duration: string
  date: string
  reward: number
  highlight?: boolean
  img?: string
  onClick?: () => any
}

const TaskItem: React.FC<Props & ITaskItem> = ({
  className,
  issuer,
  title,
  duration,
  date,
  reward,
  highlight,
  img,
  onClick,
}) => {
  return (
    <div
      className={`taskitem ${highlight ? 'unlocked' : ''}`}
      onClick={onClick}>
      <div className='taskitem__img__wrapper'>
        <img className='taskitem__img' src={img} />
      </div>
      <div className='taskitem__content'>
        <div className='taskitem__data'>
          <div style={{ flex: 1 }}>
            <div className='taskitem__title'>{issuer}</div>
            <div>{title}</div>
          </div>
          <div>
            <div className='taskitem__reward highlight'>{reward} P</div>
          </div>
        </div>
        <div className='taskitem__time'>
          <div>{date}</div>
          <div>{duration}</div>
        </div>
      </div>
    </div>
  )
}

export default TaskItem
