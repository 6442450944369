import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../components/Button'

import { IRewardItem } from '../components/RewardItemCard'
import RewardItemRow from '../components/RewardItemRow'
import useModal from '../hooks/useModal'

type Props = {}

const rewards: IRewardItem[] = [
  {
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrtdWolaIL_HnBhf3x42AY2cLUvInY51STIQ&usqp=CAU',
    issuer: 'Deutsche Bahn',
    description: '1 Monats-Ticket regional',
    cost: 2000,
    location: 'Nordrhein-Westfalen',
  },
  {
    img: 'https://edeka-geldermann.de/wp-content/uploads/2019/08/edeka-geldermann-aldehoven-aussen.jpg',
    issuer: 'Edeka Aachen',
    description: '30% Rabatt auf deinen nächsten Einkauf bis 50€',
    cost: 800,
    location: 'Aachen',
  },
  {
    img: 'https://is5-ssl.mzstatic.com/image/thumb/Purple126/v4/88/9d/a7/889da76d-0766-8e32-62ba-1e817e0912c4/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/1200x630wa.png',
    issuer: 'Burger King Aachen',
    description: '1 kostenloses King-Menü nach Wahl',
    cost: 500,
    location: 'Eschweiler',
  },
]

const Redeem: React.FC<Props> = () => {
  const { setModal } = useModal()
  const navigate = useNavigate()

  return (
    <div>
      <p style={{ opacity: 0.8, margin: 'var(--space-md) 0' }}>
        Hier findest du verfügbare Belohnungen. Scanne deinen Code bei unseren
        Partnern ein und erhalte tolle Geschenke und Rabatte!
      </p>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          style={{ padding: 'var(--space-lg) var(--space-xxl)' }}
          onClick={() =>
            setModal(prev => ({ ...prev, showModal: true, variant: 'camera' }))
          }>
          <h1>Einlösen</h1>
        </Button>
      </div>
      <div>
        <h2
          className='highlight'
          style={{ display: 'inline-block', margin: 'var(--space-lg) 0' }}>
          Belohnungen in deiner Nähe
        </h2>
        {rewards.map((r: IRewardItem) => (
          <RewardItemRow
            {...r}
            onClick={() => navigate('/reward', { state: { reward: r } })}
          />
        ))}
      </div>
    </div>
  )
}

export default Redeem
